<template>
  <v-container fill-height class="recovery-info-page">
    <v-row align="center" justify="center">
      <v-col sm="6" md="4">
        <v-card elevation="2" :loading="loading">
          <v-card-title>Revert Config Change</v-card-title>
          <v-card-text v-if="!requestResult" class="main-text">
            <p>
              Your recovery password and user information
              for {{ company }} was
              recently updated without the User Password.
              If this is planned then you may ignore this message.
            </p>
            <p>
              If this change was not done by you then please click the button "Revert"
              and these changes will be reversed.
            </p>
            <p>Thank you, {{ company }} Security.</p>
          </v-card-text>
          <v-card-text v-if="requestResult" class="request-text">
            <p :class="requestResult.color">{{ requestResult.text }}</p>
          </v-card-text>
          <v-card-actions v-if="!requestResult">
            <v-btn
              :loading="loading"
              block
              color="primary"
              @click="revertRecoveryInfo"
              class="revert-btn"
              >Revert</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import {
  AQUILA_THEME_NAME, DELPHINUS_THEME_NAME, dismissAction, logAndExtractMessage,
} from "../utils/utils";
import { IS_MOBILE } from "@/utils/is-mobile";
import theme from "../../app-theme.config";

export default {
  name: "RevertRecoveryInfo",

  data: () => ({
    theme,
    loading: false,
    requestResult: undefined,
  }),

  computed: {
    company() {
      if (this.theme.name == AQUILA_THEME_NAME) {
        return "Altair";
      }

      if (this.theme.name == DELPHINUS_THEME_NAME) {
        return "Pythia";
      }

      return "";
    },
  },

  methods: {
    async revertRecoveryInfo() {
      this.loading = true;
      try {
        this.requestResult = await this.$store.dispatch("userModule/revertRecoveryInfo", this.$route.params.id);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.loading = false;
    },
  },

  mounted() {
    if (!IS_MOBILE) this.$store.commit("coreModule/toggleDrawer");
  },
};
</script>
